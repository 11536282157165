import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Conf from '../../config.json';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import ErrorBar from '../ErrorBar/ErrorBar';


function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function EducationalDetails({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.educationalDetailsValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(4);
        }
    };

    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item xs={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Educational Details
                    </Typography>
                </Grid>
                <Grid item xs={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 3 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>
                <Grid item xs={8} sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                        <FormLabel id="label-matriculated" sx={sxFormLabel}>Matriculated Yet? <Star /></FormLabel>
                        <Select
                            labelId="label-matriculated"
                            value={props.matriculated}
                            error={props.matriculated === "none"}
                            onChange={(ev) => { props.setMatriculated(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            <MenuItem key={1} value={"Yes"}>{"Yes"}</MenuItem>
                            <MenuItem key={2} value={"No"}>{"No"}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {props.matriculated === "Yes" &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-yearmatriculated" sx={sxFormLabel}>Year Matriculated <Star /></FormLabel>
                            <Select
                                labelId="label-yearmatriculated"
                                value={props.yearmatriculated}
                                error={props.yearmatriculated === "none"}
                                onChange={(ev) => { props.setYearmatriculated(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                {Conf.lists.years.map((year, index) => (
                                    <MenuItem key={index} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {props.matriculated === "Yes" &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-tertiaryinstitution" sx={sxFormLabel}>Are you currently at a tertiary institution? <Star /></FormLabel>
                            <Select
                                labelId="label-tertiaryinstitution"
                                value={props.tertiaryinstitution}
                                error={props.tertiaryinstitution === "none"}
                                onChange={(ev) => { props.setTertiaryinstitution(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                <MenuItem key={1} value={"Yes"}>{"Yes"}</MenuItem>
                                <MenuItem key={2} value={"No"}>{"No"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {((props.matriculated === "Yes") && (props.tertiaryinstitution === "Yes")) &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-tertiaryinstitutionname" sx={sxFormLabel}>Name of Current Institution <Star /></FormLabel>
                            <Select
                                labelId="label-tertiaryinstitutionname"
                                value={props.tertiaryinstitutionname}
                                error={props.tertiaryinstitutionname === "none"}
                                onChange={(ev) => { props.setTertiaryinstitutionname(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                {Conf.lists.institutions.map((institution, index) => (
                                    <MenuItem key={index} value={institution}>{institution}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {((props.matriculated === "Yes") && (props.tertiaryinstitution === "Yes") && (props.tertiaryinstitutionname === "Other")) &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-otherinstitutionname" sx={sxFormLabel}>Enter institution name <Star /></FormLabel>
                            <TextField
                                required
                                fullWidth
                                error={props.otherinstitutionname ? false : true}
                                label="Other institution name"
                                value={props.otherinstitutionname}
                                onChange={(ev) => { props.setOtherinstitutionname(ev.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                }
                {(props.tertiaryinstitution === "No" || props.tertiaryinstitution === "none") &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-highschoolprovince" sx={sxFormLabel}>High School Province <Star /></FormLabel>
                            <Select
                                labelId="label-highschoolprovince"
                                value={props.highschoolprovince}
                                error={props.highschoolprovince === "none"}
                                onChange={(ev) => { props.setHighschoolprovince(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                {Conf.lists.provinces.map((province, index) => (
                                    <MenuItem key={index} value={province}>{province}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }

                {(props.tertiaryinstitution === "No" || props.tertiaryinstitution === "none") &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-highschoolname" sx={sxFormLabel}>Name of High School <Star /></FormLabel>
                            <TextField
                                required
                                fullWidth
                                error={props.highschoolname ? false : true}
                                label="Enter name of school"
                                value={props.highschoolname}
                                onChange={(ev) => { props.setHighschoolname(ev.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                }

                {((props.matriculated === "Yes") && (props.tertiaryinstitution === "Yes")) &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-levelstudy" sx={sxFormLabel}>Level of study you are currently in <Star /></FormLabel>
                            <Select
                                labelId="levelstudy"
                                value={props.levelstudy}
                                error={props.levelstudy === "none"}
                                onChange={(ev) => { props.setLevelstudy(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                {Conf.lists.levels.map((level, index) => (
                                    <MenuItem key={index} value={level}>{level}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {((props.matriculated === "Yes") && (props.tertiaryinstitution === "Yes")) &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-yearstudy" sx={sxFormLabel}>Year of study you are currently in <Star /></FormLabel>
                            <Select
                                labelId="yearstudy"
                                value={props.yearstudy}
                                error={props.yearstudy === "none"}
                                onChange={(ev) => { props.setYearstudy(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                {Conf.lists.yearsStudy.map((year, index) => (
                                    <MenuItem key={index} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {((props.matriculated === "Yes") && (props.tertiaryinstitution === "Yes")) &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-studentnumber" sx={sxFormLabel}>Student Number <Star /></FormLabel>
                            <TextField
                                required
                                fullWidth
                                error={props.studentnumber ? false : true}
                                label="Enter student number"
                                value={props.studentnumber}
                                onChange={(ev) => { props.setStudentnumber(ev.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                }
                {((props.matriculated === "No")) &&
                    <Grid item xs={8} sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                            <FormLabel id="label-applicationnumber" sx={sxFormLabel}>Application Number</FormLabel>
                            <TextField
                                fullWidth
                                error={false}
                                label="Enter university application number"
                                value={props.applicationnumber}
                                onChange={(ev) => { props.setApplicationnumber(ev.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={16}>

                </Grid>
                {(props.tertiaryinstitution === "No" || props.tertiaryinstitution === "none") &&
                    <Grid container columns={16} spacing={3} padding={2}>
                        {props.matriculated === "No" &&
                            <Grid item xs={8} sx={{ width: "100%" }}>
                                <Typography sx={sxFormLabel}>
                                    Grade 11 Results <Star />
                                </Typography>
                            </Grid>
                        }

                        <Grid item xs={props.matriculated === "No" ? 8 : 16}>
                            <Typography sx={sxFormLabel}>
                                Grade 12 Results {props.matriculated === "Yes" && <Star />}
                            </Typography>
                        </Grid>
                        <Grid item xs={16}>
                            <Divider sx={{ mt: 0, mb: 0 }} />
                        </Grid>

                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-1"} sx={sxFormLabel}>Subject 1 <Star /></FormLabel>
                                    <Select
                                        labelId={"label-results-11-1"}
                                        value={props.gr11subj1}
                                        error={props.gr11subj1 === "none"}
                                        onChange={(ev) => { props.setGr11subj1(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-1"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                    <TextField
                                        fullWidth
                                        error={!props.gr11mark1}
                                        label="%"
                                        value={props.gr11mark1}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark1(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-1"} sx={sxFormLabel}>Subject 1 {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <Select
                                    labelId={"label-results-12-1"}
                                    value={props.gr12subj1}
                                    error={props.gr12subj1 === "none" && props.matriculated === "Yes"}
                                    onChange={(ev) => { props.setGr12subj1(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-1"} sx={sxFormLabel}>Mark {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.gr12mark1 && props.matriculated === "Yes"}
                                    label="%"
                                    value={props.gr12mark1}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark1(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-2"} sx={sxFormLabel}>Subject 2 <Star /></FormLabel>
                                    <Select
                                        labelId={"label-results-11-2"}
                                        value={props.gr11subj2}
                                        error={props.gr11subj2 === "none"}
                                        onChange={(ev) => { props.setGr11subj2(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-2"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                    <TextField
                                        fullWidth
                                        error={!props.gr11mark2}
                                        label="%"
                                        value={props.gr11mark2}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark2(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-2"} sx={sxFormLabel}>Subject 2 {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <Select
                                    labelId={"label-results-12-2"}
                                    value={props.gr12subj2}
                                    error={props.gr12subj2 === "none" && props.matriculated === "Yes"}
                                    onChange={(ev) => { props.setGr12subj2(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-2"} sx={sxFormLabel}>Mark {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.gr12mark2 && props.matriculated === "Yes"}
                                    label="%"
                                    value={props.gr12mark2}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark2(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-3"} sx={sxFormLabel}>Subject 3 <Star /></FormLabel>
                                    <Select
                                        labelId={"label-results-11-3"}
                                        value={props.gr11subj3}
                                        error={props.gr11subj3 === "none"}
                                        onChange={(ev) => { props.setGr11subj3(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-3"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                    <TextField
                                        fullWidth
                                        error={!props.gr11mark3}
                                        label="%"
                                        value={props.gr11mark3}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark3(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-3"} sx={sxFormLabel}>Subject 3 {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <Select
                                    labelId={"label-results-12-3"}
                                    value={props.gr12subj3}
                                    error={props.gr12subj3 === "none" && props.matriculated === "Yes"}
                                    onChange={(ev) => { props.setGr12subj3(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-3"} sx={sxFormLabel}>Mark {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.gr12mark3 && props.matriculated === "Yes"}
                                    label="%"
                                    value={props.gr12mark3}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark3(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-4"} sx={sxFormLabel}>Subject 4 <Star /></FormLabel>
                                    <Select
                                        labelId={"label-results-11-4"}
                                        value={props.gr11subj4}
                                        error={props.gr11subj4 === "none"}
                                        onChange={(ev) => { props.setGr11subj4(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-4"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                    <TextField
                                        fullWidth
                                        error={!props.gr11mark4}
                                        label="%"
                                        value={props.gr11mark4}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark4(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-4"} sx={sxFormLabel}>Subject 4 {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <Select
                                    labelId={"label-results-12-4"}
                                    value={props.gr12subj4}
                                    error={props.gr12subj4 === "none" && props.matriculated === "Yes"}
                                    onChange={(ev) => { props.setGr12subj4(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-4"} sx={sxFormLabel}>Mark {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.gr12mark4 && props.matriculated === "Yes"}
                                    label="%"
                                    value={props.gr12mark4}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark4(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-5"} sx={sxFormLabel}>Subject 5 <Star /></FormLabel>
                                    <Select
                                        labelId={"label-results-11-5"}
                                        value={props.gr11subj5}
                                        error={props.gr11subj5 === "none"}
                                        onChange={(ev) => { props.setGr11subj5(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-5"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                    <TextField
                                        fullWidth
                                        error={!props.gr11mark5}
                                        label="%"
                                        value={props.gr11mark5}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark5(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-5"} sx={sxFormLabel}>Subject 5 {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <Select
                                    labelId={"label-results-12-5"}
                                    value={props.gr12subj5}
                                    error={props.gr12subj5 === "none" && props.matriculated === "Yes"}
                                    onChange={(ev) => { props.setGr12subj5(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-5"} sx={sxFormLabel}>Mark {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.gr12mark5 && props.matriculated === "Yes"}
                                    label="%"
                                    value={props.gr12mark5}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark5(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-6"} sx={sxFormLabel}>Subject 6 <Star /></FormLabel>
                                    <Select
                                        labelId={"label-results-11-6"}
                                        value={props.gr11subj6}
                                        error={props.gr11subj6 === "none"}
                                        onChange={(ev) => { props.setGr11subj6(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-6"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                    <TextField
                                        fullWidth
                                        error={!props.gr11mark6}
                                        label="%"
                                        value={props.gr11mark6}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark6(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-6"} sx={sxFormLabel}>Subject 6 {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <Select
                                    labelId={"label-results-12-6"}
                                    value={props.gr12subj6}
                                    error={props.gr12subj6 === "none" && props.matriculated === "Yes"}
                                    onChange={(ev) => { props.setGr12subj6(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-6"} sx={sxFormLabel}>Mark {props.matriculated === "Yes" && <Star />}</FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.gr12mark6 && props.matriculated === "Yes"}
                                    label="%"
                                    value={props.gr12mark6}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark6(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-7"} sx={sxFormLabel}>Subject 7</FormLabel>
                                    <Select
                                        labelId={"label-results-11-7"}
                                        value={props.gr11subj7}
                                        onChange={(ev) => { props.setGr11subj7(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-7"} sx={sxFormLabel}>Mark</FormLabel>
                                    <TextField
                                        fullWidth
                                        label="%"
                                        value={props.gr11mark7}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark7(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }

                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-7"} sx={sxFormLabel}>Subject 7</FormLabel>
                                <Select
                                    labelId={"label-results-12-7"}
                                    value={props.gr12subj7}
                                    onChange={(ev) => { props.setGr12subj7(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-7"} sx={sxFormLabel}>Mark</FormLabel>
                                <TextField
                                    fullWidth
                                    label="%"
                                    value={props.gr12mark7}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark7(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-8"} sx={sxFormLabel}>Subject 8</FormLabel>
                                    <Select
                                        labelId={"label-results-11-8"}
                                        value={props.gr11subj8}
                                        onChange={(ev) => { props.setGr11subj8(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-8"} sx={sxFormLabel}>Mark</FormLabel>
                                    <TextField
                                        fullWidth
                                        label="%"
                                        value={props.gr11mark8}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark8(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-8"} sx={sxFormLabel}>Subject 8</FormLabel>
                                <Select
                                    labelId={"label-results-12-8"}
                                    value={props.gr12subj8}
                                    onChange={(ev) => { props.setGr12subj8(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-8"} sx={sxFormLabel}>Mark</FormLabel>
                                <TextField
                                    fullWidth
                                    label="%"
                                    value={props.gr12mark8}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark8(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-9"} sx={sxFormLabel}>Subject 9</FormLabel>
                                    <Select
                                        labelId={"label-results-11-9"}
                                        value={props.gr11subj9}
                                        onChange={(ev) => { props.setGr11subj9(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-9"} sx={sxFormLabel}>Mark</FormLabel>
                                    <TextField
                                        fullWidth
                                        label="%"
                                        value={props.gr11mark9}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark9(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }} v
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-9"} sx={sxFormLabel}>Subject 9</FormLabel>
                                <Select
                                    labelId={"label-results-12-9"}
                                    value={props.gr12subj9}
                                    onChange={(ev) => { props.setGr12subj9(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-9"} sx={sxFormLabel}>Mark</FormLabel>
                                <TextField
                                    fullWidth
                                    label="%"
                                    value={props.gr12mark9}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark9(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        {props.matriculated === "No" &&
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-results-11-10"} sx={sxFormLabel}>Subject 10</FormLabel>
                                    <Select
                                        labelId={"label-results-11-10"}
                                        value={props.gr11subj10}
                                        onChange={(ev) => { props.setGr11subj10(ev.target.value) }}
                                    >
                                        <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                        {Conf.lists.subjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {props.matriculated === "No" &&
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel id={"label-mark-11-10"} sx={sxFormLabel}>Mark</FormLabel>
                                    <TextField
                                        fullWidth
                                        label="%"
                                        value={props.gr11mark10}
                                        onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr11mark10(ev.target.value) }}
                                        onFocus={(ev) => { ev.target.select() }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 6 : 12}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-results-12-10"} sx={sxFormLabel}>Subject 10</FormLabel>
                                <Select
                                    labelId={"label-results-12-10"}
                                    value={props.gr12subj10}
                                    onChange={(ev) => { props.setGr12subj10(ev.target.value) }}
                                >
                                    <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                    {Conf.lists.subjects.map((subject, index) => (
                                        <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={props.matriculated === "No" ? 2 : 4}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-12-10"} sx={sxFormLabel}>Mark</FormLabel>
                                <TextField
                                    fullWidth
                                    label="%"
                                    value={props.gr12mark10}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setGr12mark10(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        {props.matriculated === "No" &&
                            <Grid item xs={8}>
                                <Grid container justify="space-between" columns={8} sx={{ backgroundColor: "#007C92", borderRadius: 2, p: 2 }}>
                                    <Grid item xs={4}>
                                        <Typography sx={{ color: "#FFFFFF", textAlign: "left" }}>Average</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ color: "#FFFFFF", textAlign: "right" }}>{props.gr11avg}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={props.matriculated === "No" ? 8 : 16}>
                            <Grid container justify="space-between" columns={8} sx={{ backgroundColor: "#007C92", borderRadius: 2, p: 2 }}>
                                <Grid item xs={4}>
                                    <Typography sx={{ color: "#FFFFFF", textAlign: "left" }}>Average</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{ color: "#FFFFFF", textAlign: "right" }}>{props.gr12avg}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={16}>
                            <Box
                                justify="space-between"
                                columns={8} sx={{ borderRadius: 2, p: 2, margin: "auto", display: "flex", alignItems: 'center' }}
                                style={{ background: 'linear-gradient(to right, #9C2AA0, #00B0CA)' }}
                            >
                                <InfoIcon sx={{ color: "#FFFFFF", mr: 1 }} /><Typography sx={{ color: "#FFFFFF" }}>A minimum Requirement of 70% is necessary to proceed</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {((props.matriculated === "Yes") && (props.tertiaryinstitution === "Yes")) &&
                    <Grid container columns={16} spacing={3} padding={2}>
                        <Grid item xs={8}>
                            <Typography sx={sxFormLabel}>
                                Latest Results
                            </Typography>
                        </Grid>
                        <Grid item xs={16}>
                            <Divider sx={{ mt: 0, mb: 0 }} />
                        </Grid>

                        <Grid item xs={14}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-subj-ter-1"} sx={sxFormLabel}>Subject 1<Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.tersubj1}
                                    label="Subject"
                                    value={props.tersubj1}
                                    onChange={(ev) => { props.setTersubj1(ev.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-ter-1"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.termark1}
                                    label="%"
                                    value={props.termark1}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setTermark1(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={14}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-subj-ter-2"} sx={sxFormLabel}>Subject 2<Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.tersubj2}
                                    label="Subject"
                                    value={props.tersubj2}
                                    onChange={(ev) => { props.setTersubj2(ev.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-ter-2"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.termark2}
                                    label="%"
                                    value={props.termark2}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setTermark2(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={14}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-subj-ter-3"} sx={sxFormLabel}>Subject 3<Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.tersubj3}
                                    label="Subject"
                                    value={props.tersubj3}
                                    onChange={(ev) => { props.setTersubj3(ev.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-ter-3"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.termark3}
                                    label="%"
                                    value={props.termark3}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setTermark3(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={14}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-subj-ter-4"} sx={sxFormLabel}>Subject 4<Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.tersubj4}
                                    label="Subject"
                                    value={props.tersubj4}
                                    onChange={(ev) => { props.setTersubj4(ev.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-ter-4"} sx={sxFormLabel}>Mark <Star /></FormLabel>
                                <TextField
                                    fullWidth
                                    error={!props.termark4}
                                    label="%"
                                    value={props.termark4}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setTermark4(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={14}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-subj-ter-5"} sx={sxFormLabel}>Subject 5</FormLabel>
                                <TextField
                                    fullWidth
                                    label="Subject"
                                    value={props.tersubj5}
                                    onChange={(ev) => { props.setTersubj5(ev.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-ter-5"} sx={sxFormLabel}>Mark</FormLabel>
                                <TextField
                                    fullWidth
                                    label="%"
                                    value={props.termark5}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setTermark5(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={14}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-subj-ter-6"} sx={sxFormLabel}>Subject 6</FormLabel>
                                <TextField
                                    fullWidth
                                    label="Subject"
                                    value={props.tersubj6}
                                    onChange={(ev) => { props.setTersubj6(ev.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel id={"label-mark-ter-6"} sx={sxFormLabel}>Mark</FormLabel>
                                <TextField
                                    fullWidth
                                    label="%"
                                    value={props.termark6}
                                    onChange={(ev) => { if (parseInt(ev.target.value) <= 100 && parseInt(ev.target.value) >= 0) props.setTermark6(ev.target.value) }}
                                    onFocus={(ev) => { ev.target.select() }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={16}>
                            <Grid container justify="space-between" columns={8} sx={{ backgroundColor: "#007C92", borderRadius: 2, p: 2 }}>
                                <Grid item xs={4}>
                                    <Typography sx={{ color: "#FFFFFF", textAlign: "left" }}>Average</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{ color: "#FFFFFF", textAlign: "right" }}>{props.teravg}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={16}>
                            <Box
                                justify="space-between"
                                columns={8} sx={{ borderRadius: 2, p: 2, margin: "auto", display: "flex", alignItems: 'center' }}
                                style={{ background: 'linear-gradient(to right, #9C2AA0, #00B0CA)' }}
                            >
                                <InfoIcon sx={{ color: "#FFFFFF", mr: 1 }} /><Typography sx={{ color: "#FFFFFF" }}>A minimum Requirement of 65% is necessary to proceed</Typography>
                            </Box>
                        </Grid>


                    </Grid>
                }
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default EducationalDetails;